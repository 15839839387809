import { collection, doc, getDocs, getDoc, query } from "firebase/firestore";

import { db, auth } from "auth/FirebaseAuth";
import { AUTH_USER_DATA } from "constants/AuthConstant";

const FirebaseLicenseService = {};

FirebaseLicenseService.getSubscriptionData = async () => {
  try {
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    // Get the user's subscriptionID
    const userDocRef = doc(db, "users", owner_uid);
    const userDoc = await getDoc(userDocRef);
    const subscriptionID = userDoc.data().subscription.id;

    // Get the subscription data
    const subscriptionDocRef = doc(db, "subscriptions", subscriptionID);
    const subscriptionDoc = await getDoc(subscriptionDocRef);
    const subscriptionData = subscriptionDoc.data();

    return subscriptionData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
// const user = await FirebaseUserService.getUserData;
FirebaseLicenseService.projectsLimitCheck = async projectId => {
  try {
    const userUid = auth.currentUser.uid;

    // Get the user's subscriptionID
    const userDocRef = doc(db, "users", userUid);
    const userDoc = await getDoc(userDocRef);
    const maxprojects = userDoc.data().sitesLimit;
    const availableArticleGenerations = userDoc.data().availableGenerations;

    // Get the projects data
    const projectsDataCollectionRef = collection(
      db,
      `users/${userUid}/projectsData`
    );
    const projectsDataSnapshot = await getDocs(
      query(projectsDataCollectionRef)
    );

    const projectsData = [];

    for (const doc of projectsDataSnapshot.docs) {
      const project = doc.data();
      project.active && projectsData.push(project);
    }

    return {
      projectsData,
      maxprojects,
      projectsLimitIsExceeded:
        projectsData.length >= maxprojects &&
        !projectsData.some(project => project.projectId === projectId),
      availableArticleGenerations,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};
// FirebaseLicenseService.projectsLimitCheck = async () => {
//   try {
//     const userUid = auth.currentUser.uid;

//     // Get the user's subscriptionID
//     const userDocRef = doc(db, "users", userUid);
//     const userDoc = await getDoc(userDocRef);
//     const subscriptionID = userDoc.data().subscription.id;

//     // Get the subscription data
//     const subscriptionDocRef = doc(db, "subscriptions", subscriptionID);
//     const subscriptionDoc = await getDoc(subscriptionDocRef);
//     const { maxprojects } = subscriptionDoc.data();

//     // Get the projects data
//     const projectsDataCollectionRef = collection(
//       db,
//       `users/${userUid}/projectsData`
//     );
//     const projectsDataSnapshot = await getDocs(
//       query(projectsDataCollectionRef)
//     );

//     const projectsData = [];

//     for (const doc of projectsDataSnapshot.docs) {
//       const project = doc.data();
//       project.active && projectsData.push(project);
//     }

//     return {
//       projectsData,
//       maxprojects,
//       projectsLimitIsExceeded: projectsData.length >= maxprojects,
//     };
//   } catch (error) {
//     console.log(error);
//     throw error;
//   }
// };
export default FirebaseLicenseService;
