import React from "react";
import { Button, Modal } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const BoostYourPlanNotification = ({
  isBoostYourPlanNotificationVisible,
  setIsBoostYourPlanNotificationVisible,
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <WarningOutlined
            style={{
              color: "#FF9966",
              fontSize: "24px",
              marginRight: "10px",
            }}
          />
          <span style={{ fontSize: "24px", fontWeight: "bold" }}>Warning!</span>
        </div>
      }
      open={isBoostYourPlanNotificationVisible}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              setIsBoostYourPlanNotificationVisible(false);
              setTimeout(() => {
                navigate(`${APP_PREFIX_PATH}/payment`);
              }, 0);
            }}
            style={{
              fontSize: "16px",
              padding: "10px 20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40px",
              transition: "background-color 0.3s ease",
            }}
          >
            UPGRADE
          </Button>
        </div>
      }
      bodyStyle={{
        backgroundColor: "#fffbe6",
        textAlign: "center",
        padding: "20px",
      }}
      onCancel={() => setIsBoostYourPlanNotificationVisible(false)}
      closable={true} // Enable close button
      maskClosable={true} // Enable closing modal when clicking outside
    >
      <div>
        <p style={{ fontSize: "18px", margin: "8px 0" }}>
          To add more projects and publish more articles upgrade your plan.
        </p>
      </div>
    </Modal>
  );
};

export { BoostYourPlanNotification };
