export const UPDATE_ARTICLE_DATA_FROM_NOTIFICATION_PAYLOAD =
  "Update article data from notification payload";

export const HAS_PREVIEW_REQUIRED_KEYS = [
  "projectSubject",
  "subjectId",
  "siteDescription",
  "content",
];

export const HAS_ARTICLE_UPDATE_DATA_REQUIRED_KEYS = [
  "userId",
  "projectId",
  "articleId",
  "type",
];

export const HAS_ARTICLES_RELOAD_DATA_REQUIRED_KEYS = [
  "userId",
  "projectId",
  "articleId",
  "subjectId",
];

export const HAS_PLAN_UPDATE_REQUIRED_KEYS = [
  "availableGenerations",
  "subscription",
  "sitesLimit",
];
