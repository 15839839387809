import React, { useState, useEffect } from "react";
import { Drawer, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const COOKIE_CONSENT_KEY = "cookieConsent";
// localStorage.removeItem("cookieConsent");
const useCookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Проверяем наличие записи о согласии в localStorage
    const consent = localStorage.getItem(COOKIE_CONSENT_KEY);
    if (!consent) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  const acceptCookies = () => {
    // Сохраняем в localStorage информацию о согласии пользователя
    localStorage.setItem(COOKIE_CONSENT_KEY, "accepted");
    setIsVisible(false);
  };

  return { isVisible, acceptCookies };
};

const CookieConsentDrawer = () => {
  const { isVisible, acceptCookies } = useCookieConsent();

  if (isVisible === null || !isVisible) {
    return null;
  }

  return (
    <Drawer
      placement="bottom"
      open={isVisible}
      closable={false}
      height={180}
      mask={false}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fafafa",
        boxShadow: "0 -4px 10px rgba(0, 0, 0, 0.1)",
        padding: "20px 10px",
        textAlign: "center",
      }}
    >
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <InfoCircleOutlined
          style={{ fontSize: "24px", color: "#1890ff", marginRight: "8px" }}
        />
        <h4 style={{ fontSize: "18px", color: "#595959", margin: 0 }}>
          We use cookies to improve your experience.
        </h4>
      </div>
      <p
        style={{
          color: "#8c8c8c",
          marginBottom: "20px",
          fontSize: "14px",
          lineHeight: "1.5",
        }}
      >
        By continuing, you accept our cookie policy.
      </p>
      <Button type="primary" size="large" onClick={acceptCookies}>
        Accept
      </Button>
    </Drawer>
  );
};

export default CookieConsentDrawer;
