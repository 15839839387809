import { getAuth } from "firebase/auth";
import axios from "axios";

import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { db } from "auth/FirebaseAuth";
import { AUTH_USER_DATA } from "constants/AuthConstant";
import { API_BASE_URL } from "constants/ApiConstant";
const auth = getAuth();

const FirebaseUserService = {};

FirebaseUserService.getUserData = async () => {
  try {
    if (!JSON.parse(localStorage.getItem(AUTH_USER_DATA))) return;
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    const userDataDocRef = doc(db, `users/${owner_uid}`);

    const userDataSnapshot = await getDoc(userDataDocRef);

    if (userDataSnapshot.exists()) {
      const userData = userDataSnapshot.data();
      return userData;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

FirebaseUserService.getSubscriptionData = async subscription_id => {
  try {
    const subscriptionDocRef = doc(db, `subscriptions/${subscription_id}`);
    const subscriptionSnapshot = await getDoc(subscriptionDocRef);

    if (subscriptionSnapshot.exists()) {
      const subscriptionData = subscriptionSnapshot.data();

      return subscriptionData;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

// FirebaseUserService.saveUserFCMToken = async token => {
//   try {
//     // Получаем данные авторизованного пользователя из localStorage
//     const userData = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
//     if (!userData) return false;

//     const { owner_uid } = userData;
//     const userDataDocRef = doc(db, `users/${owner_uid}`);

//     // Получаем данные пользователя из Firestore
//     const userDataSnapshot = await getDoc(userDataDocRef);

//     // Если документ пользователя существует
//     if (userDataSnapshot.exists()) {
//       const userData = userDataSnapshot.data();

//       // Проверяем наличие массива FCMTokens
//       const { FCMTokens = [] } = userData;

//       // Проверяем, существует ли уже данный токен в массиве
//       if (!FCMTokens.includes(token)) {
//         // Если токен отсутствует, добавляем его в массив
//         const updatedTokens = [...FCMTokens, token];

//         // Обновляем данные пользователя в Firestore
//         await updateDoc(userDataDocRef, { FCMTokens: updatedTokens });

//         // Возвращаем true, так как токен был добавлен
//         return true;
//       }

//       // Если токен уже существует в массиве, возвращаем true
//       return true;
//     } else {
//       // Если документ пользователя не найден, возвращаем null
//       return false;
//     }
//   } catch (error) {
//     throw error; // Обрабатываем и выбрасываем ошибку
//   }
// };

// FirebaseUserService.updateUserData = async updatedData => {
//   try {
//     const storedUser = JSON.parse(localStorage.getItem(AUTH_USER_DATA));

//     if (!storedUser) {
//       throw new Error("User data not found in localStorage.");
//     }

//     const { owner_uid } = storedUser;
//     const userDataDocRef = doc(db, `users/${owner_uid}`);

//     const userDataSnapshot = await getDoc(userDataDocRef);

//     if (!userDataSnapshot.exists()) {
//       throw new Error("User data not found in Firestore.");
//     }

//     const currentData = userDataSnapshot.data();

//     const updatedUserData = {
//       ...currentData,
//       displayName:
//         updatedData.displayName !== undefined
//           ? updatedData.displayName
//           : currentData.displayName,
//       autoApproveSocialMedia:
//         updatedData.autoApproveSocialMedia !== undefined
//           ? updatedData.autoApproveSocialMedia
//           : currentData.autoApproveSocialMedia,
//     };
//     // updatedUserData.sitesLimit = 1;
//     // Infinity
//     delete updatedUserData.sitesLimit;
//     delete updatedUserData.availableGenerations;
//     console.log(`updatedUserData`, updatedUserData);
//     await updateDoc(userDataDocRef, updatedUserData);

//     localStorage.setItem(AUTH_USER_DATA, JSON.stringify(updatedUserData));

//     return updatedUserData;
//   } catch (error) {
//     throw error;
//   }
// };

FirebaseUserService.saveUserFCMToken = async (token, owner_uid) => {
  try {
    const url = `${API_BASE_URL}/user/add-fcm`;
    await axios.post(url, {
      userId: owner_uid,
      fcmToken: token,
    });

    return true;
  } catch (error) {
    console.log(`Error in saveUserFCMToken`, error);
    return false;
  }
};

FirebaseUserService.updateUserData = async updatedData => {
  try {
    const user = auth.currentUser;

    if (!user) {
      throw new Error("User not authenticated");
    }

    // user token
    const idToken = await user.getIdToken();
    const url = `${API_BASE_URL}/user/update`;
    const response = await axios.post(url, {
      userId: user.uid,
      displayName: updatedData.displayName,
      autoApproveSocialMedia: updatedData.autoApproveSocialMedia,
      idToken,
    });

    // console.log("User data updated:", response.data);
    localStorage.setItem(
      AUTH_USER_DATA,
      JSON.stringify(response.data.updatedUserData)
    );

    return response.data.updatedUserData;
  } catch (error) {
    console.error("Error updating user data:", error);
  }
};

FirebaseUserService.getUserPaymentHistoryData = async () => {
  try {
    const authUserData = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    if (!authUserData) return null;

    const { owner_uid } = authUserData;
    const userDataDocRef = doc(db, `users/${owner_uid}`);
    const userDataSnapshot = await getDoc(userDataDocRef);

    if (userDataSnapshot.exists()) {
      const paymentHistoryCollectionRef = collection(
        db,
        `users/${owner_uid}/paymentHistory`
      );
      const paymentHistorySnapshot = await getDocs(
        query(paymentHistoryCollectionRef, orderBy("dateCreated", "desc"))
      );

      if (!paymentHistorySnapshot.empty) {
        const paymentHistory = paymentHistorySnapshot.docs.map(doc =>
          doc.data()
        );
        return paymentHistory;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.log(`Error getUserPaymentHistoryData`, error);
    throw error;
  }
};

FirebaseUserService.getArticlesCounts = async projectId => {
  try {
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    const url = `${API_BASE_URL}/articles/counts/${owner_uid}/${projectId}`;
    const result = await axios.get(url);

    return result.data;
  } catch (error) {
    throw error;
  }
};

FirebaseUserService.postArticle = async data => {
  try {
    const url = `${API_BASE_URL}/articles/create`;
    // const response = await axios.post(url, data);
    const response = await axios.post(url, data);
    return response.data;
    // return;
  } catch (error) {
    throw error;
  }
};

export default FirebaseUserService;
