import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const usePlanUpgradeNotification = () => {
  const [isVisible, setIsVisible] = useState(false);

  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (user.availableGenerations === 0) {
        setIsVisible(true);
      }
    }, 10000);

    return () => clearTimeout(timer);
  }, [user]);

  return {
    isVisible,
    setIsVisible,
  };
};

export default usePlanUpgradeNotification;
